// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-introduction-tsx": () => import("./../../../src/pages/introduction.tsx" /* webpackChunkName: "component---src-pages-introduction-tsx" */),
  "component---src-pages-membership-tsx": () => import("./../../../src/pages/membership.tsx" /* webpackChunkName: "component---src-pages-membership-tsx" */),
  "component---src-pages-osrs-guide-tsx": () => import("./../../../src/pages/osrs-guide.tsx" /* webpackChunkName: "component---src-pages-osrs-guide-tsx" */),
  "component---src-pages-rs-3-guide-tsx": () => import("./../../../src/pages/rs3-guide.tsx" /* webpackChunkName: "component---src-pages-rs-3-guide-tsx" */)
}

